













































import { Component, Vue } from "vue-property-decorator";

@Component
export default class HomeTaskList extends Vue {
  items = [
    {
      title:
        "Aliquam eu nunc. Fusce commodo aliquam arcu. In consectetuer.turpis ut velit. Nulla facilisi.. Morbi mollis tellus ac",
      icon: "mdi-information-outline",
      color: "blue",
    },
  ];
  mini = true;
}
