




















import Vue from "vue";
import { Component } from "vue-property-decorator";
import Sparkline from "@/components/Sparkline.vue";
import HomeTaskList from "@/components/HomeTaskList.vue";

@Component({
  components: {
    Sparkline,
    HomeTaskList,
  },
})
export default class Home extends Vue {}
